<template>
	<div class="carrierWrap carrierWrapBundle" v-bind:class="{ 'expanded': (expanded === true), 'lowestPrice': (insquote.isLowest === true), 'hasError': (insquote.errored === true) }">
		<div class="carrierBasics row">
			<img class="lpCorner lpCorner-desktop show-for-medium" :src="asset('lowestPrice-orange-wide.png')" alt="Lowest Price" v-if="(insquote.isLowest === true)">
			<img class="lpCorner lpCorner-mobile hide-for-medium" :src="asset('lowestPrice-orange.png')" alt="Lowest Price" v-if="(insquote.isLowest === true)">
			<div class="carrierLogo1" v-on:click="expand()">
				<img :src="insquote.carrier_logo_url" :alt="insquote.carrier_name">
			</div>
			<div class="carrierProducts1">
				<div class="autoResult" v-show="hasAProductTotal === true && attemptedAutoQuote === true">
					<div class="quoteIconWrap"><icon-helper :glyph="'auto'" :variant="iconvariant" :classes="'carIcon'"></icon-helper></div>
					<div class="quotePrice" v-if="hasAutoTotal">
						<span class="dollarSign">$</span>
						<span class="priceNumber" v-html="numFormat(insquote.productQuotes.Auto.coverages.total)"></span>
						<span class="priceUnit">/<span v-html="insquote.totalUnit"></span><a href="#" v-on:click.prevent="scrollToDisclaimer()" class="disclaimerStar noTrack">*</a></span>
					</div>
					<div class="quoteNoPrice" v-show="hasAutoTotal === false">
						<span class="hide-for-medium">We are unable to provide this quote online. </span>Please call us for&nbsp;details.<span class="doubleDagger">&#8225;</span>
					</div>
				</div>
				<div class="homeResult" v-show="hasAProductTotal === true && attemptedHomeQuote === true">
					<div class="quoteIconWrap"><icon-helper :glyph="'home'" :variant="iconvariant" :classes="'houseIcon'"></icon-helper></div>
					<div class="quotePrice" v-if="hasHomeTotal">
						<span class="dollarSign">$</span>
						<span class="priceNumber" v-html="numFormat(insquote.productQuotes.Home.coverages.total)"></span>
						<span class="priceUnit">/<span v-html="insquote.totalUnit"></span><a href="#" v-on:click.prevent="scrollToDisclaimer()" class="disclaimerStar noTrack">*</a></span>
					</div>
					<div class="quoteNoPrice" v-show="hasHomeTotal === false">
						<span class="hide-for-medium">We are unable to provide this quote online. </span>Please call us for&nbsp;details.<span class="doubleDagger">&#8225;</span>
					</div>
				</div>
				<div class="productIconsOnly" v-show="hasAProductTotal === false">
					<icon-helper v-show="attemptedAutoQuote" :glyph="'auto'" :variant="iconvariant" :classes="'carIcon'"></icon-helper>
					<icon-helper v-show="attemptedHomeQuote" :glyph="'home'" :variant="iconvariant" :classes="'houseIcon'"></icon-helper>
				</div>
			</div>
			<div class="carrierPrice1">
				<h3 v-if="hasCombinedTotal === true">Total Per Month*</h3>
				<div class="bigPrice" v-if="hasCombinedTotal === true">
					<span class="dollarSign">$</span>
					<span class="priceNumber" v-html="numFormat(combinedTotalAmount)"></span>
					<span class="priceUnit">/<span v-html="insquote.totalUnit"></span><a href="#" v-on:click.prevent="scrollToDisclaimer()" class="disclaimerStar noTrack">*</a></span>
				</div>
				<div class="noPriceMesg" id="noPriceMesg-preferred" v-if="hasCombinedTotal === false && insquote.preferred === true">
					We are unable to provide a quote online. Please call InsuraMatch for more information.
				</div>
				<div class="noPriceMesg" id="noPriceMesg-nonPreferred-withPhone" v-if="hasCombinedTotal === false && insquote.preferred !== true && hasTel === true">
					<span class="hide-for-medium">We are unable to provide this quote online. </span>Please call {{ tel }} to&nbsp;purchase.<span class="doubleDagger">&#8225;</span>
				</div>
				<div class="noPriceMesg" id="noPriceMesg-nonPreferred-noPhone" v-if="hasCombinedTotal === false && insquote.preferred !== true && hasTel === false">
					<span class="hide-for-medium">We are unable to provide this quote online. </span>Please call us for&nbsp;details.<span class="doubleDagger">&#8225;</span>
				</div>
			</div>
			<div class="carrierActions">
				<div class="selectButtonWrap">
					<a class="quotebutton" v-on:click.prevent="startScheduleCall()"><span class="policyIconWrap"><svg class="policyIcon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50.2 50.2" style="enable-background:new 0 0 50.2 50.2;" xml:space="preserve">
						<g>
							<path d="M7.9,2.6v45h34.4v-45H7.9z M39.3,44.6H10.9V5.6h28.3V44.6z"/>
							<rect x="14.6" y="28.5" width="20.9" height="3.5"/>
							<rect x="14.6" y="35" width="20.9" height="3.5"/>
							<rect x="14.6" y="22" width="20.9" height="3.5"/>
							<rect x="14.6" y="11.6" width="10.5" height="3.5"/>
						</g></svg></span>Select</a>
				</div>
				<div class="detailsLinkWrap" v-show="hasAProductTotal === true">
					<a href="#" class="detailsLink noTrack" v-on:click.prevent="expand()">Coverage Details</a>
				</div>
			</div>
		</div>

		<div class="carrierDetails">
			<img class="closeX" :src="asset('x-circle.png')" alt="(X)" v-on:click="collapse()">
			<div class="coveragesWrap">
				<div class="coveragesBlock autoCoverages" v-if="hasAutoTotal">
					<h3>Auto Coverage</h3>
					<div class="coveragesRow">
						<div class="coverageGroup">
							<p>
								<strong>{{ coverageLabelsAuto.a }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Auto.coverages.a)"></span>
							</p>
							<p>
								<strong>{{ coverageLabelsAuto.c }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Auto.coverages.c)"></span>
							</p>
							<p>
								<strong>{{ coverageLabelsAuto.e }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Auto.coverages.e)"></span>
							</p>
						</div>
						<div class="coverageGroup">
							<p>
								<strong>{{ coverageLabelsAuto.b }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Auto.coverages.b)"></span>
							</p>
							<p>
								<strong>{{ coverageLabelsAuto.d }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Auto.coverages.d)"></span>
							</p>
						</div>
					</div>
				</div>

				<div class="coveragesBlock homeCoverages" v-if="hasHomeTotal">
					<h3>Home Coverage</h3>
					<div class="coveragesRow">
						<div class="coverageGroup">
							<p>
								<strong>{{ coverageLabelsHome.a }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Home.coverages.a)"></span>
							</p>
							<p>
								<strong>{{ coverageLabelsHome.c }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Home.coverages.c)"></span>
							</p>
							<p>
								<strong>{{ coverageLabelsHome.e }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Home.coverages.e)"></span>
							</p>
						</div>
						<div class="coverageGroup">
							<p>
								<strong>{{ coverageLabelsHome.b }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Home.coverages.b)"></span>
							</p>
							<p>
								<strong>{{ coverageLabelsHome.d }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Home.coverages.d)"></span>
							</p>
							<p>
								<strong>{{ coverageLabelsHome.f }}</strong><br>
								<span v-html="amount(insquote.productQuotes.Home.coverages.f)"></span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>


<script>
export default {
	props: {
		insquote: {
			type: Object,
			required: true
		},
		iconvariant: {
			type: String,
			default: 'wave1'
		}
	},
	data: function() {
		return {
			assetRoot: 'cdn/', /* TODO: Change to S3 later */
			expanded: false
		}
	},
	computed: {
		attemptedAutoQuote: function() {
			return (window.is.existy(this.insquote.productQuotes) && window.is.existy(this.insquote.productQuotes.Auto));
		},
		attemptedHomeQuote: function() {
			return (window.is.existy(this.insquote.productQuotes) && window.is.existy(this.insquote.productQuotes.Home));
		},
		hasAutoTotal: function() {
			if (this.insquote.hasOwnProperty('productQuotes') === false) { return false; }
			if (this.insquote.productQuotes.hasOwnProperty('Auto') === false) { return false; }
			if (this.insquote.productQuotes.Auto.hasOwnProperty('coverages') === false) { return false; }
			return (isNaN(this.insquote.productQuotes.Auto.coverages.total) === false && this.insquote.productQuotes.Auto.coverages.total != null && this.insquote.productQuotes.Auto.coverages.total > 0);
		},
		hasHomeTotal: function() {
			if (this.insquote.hasOwnProperty('productQuotes') === false) { return false; }
			if (this.insquote.productQuotes.hasOwnProperty('Home') === false) { return false; }
			if (this.insquote.productQuotes.Home.hasOwnProperty('coverages') === false) { return false; }
			return (isNaN(this.insquote.productQuotes.Home.coverages.total) === false && this.insquote.productQuotes.Home.coverages.total != null && this.insquote.productQuotes.Home.coverages.total > 0);
		},
		hasAProductTotal: function() {
			return (this.hasAutoTotal === true || this.hasHomeTotal === true);
		},
		hasCombinedTotal: function() {
			return (this.hasAutoTotal === true && this.hasHomeTotal === true);
		},
		combinedTotalAmount: function() {
			let total = 0;
			if (this.hasAutoTotal) { total += this.insquote.productQuotes.Auto.coverages.total; }
			if (this.hasHomeTotal) { total += this.insquote.productQuotes.Home.coverages.total; }
			return total;
		},
		hasTel: function() {
			return ((typeof this.insquote.carrier_phone) == 'string' && this.insquote.carrier_phone.length > 0);
		},
		tel: function() {
			if (this.hasTel === false) { return ''; }
			let out = this.insquote.carrier_phone;
			if (out.substring(0, 1) == '+') { out = out.substring(1); } // Strip the + symbol from the phone number.
			if (out.length == 11 && out.substring(0, 1) == '1') {
				// 10-digit phone number. Add dots.
				return out.substring(1, 4) + '.' + out.substring(4, 7) + '.' + out.substring(7);
			}
			// Unknown format. Return the value raw.
			return out;
		},
		isAutoQuote: function() {
			return (this.insquote.quote_type == 'auto');
		},
		coverageLabelsAuto: function() {
			return {
				a: 'Bodily injury',
				b: 'Medical payments',
				c: 'Property damage',
				d: 'Underinsured motorist',
				e: 'Uninsured motorist',
				f: ''
			};
		},
		coverageLabelsHome: function() {
			return {
				a: 'Repair/rebuild your home',
				b: 'Repair/rebuild other structures',
				c: 'Personal possessions',
				d: 'Temporary living expenses',
				e: 'Personal liability',
				f: 'Medical payments to others'
			};
		}
	},
	methods: {
		asset: function(str) {
			return this.assetRoot + str;
		},
		amount: function(n) {
			if (n == null) { return 'Call for Details'; }
			const lookups = {
				'50000/100000': `$50,000/$100,000`,
				'100000/300000': `$100,000/$300,000`,
				'250000/500000': `$250,000/$500,000`
			};
			if (Object.keys(lookups).indexOf(n) > -1) {
				return lookups[n];
			}
			return '$' + n.toLocaleString();
		},
		startScheduleCall: function() {
			window.Rates.showScheduleCallForm();
		},
		numFormat: function(n) {
			if (isNaN(n) === true || n == null) { return ''; }
			return '' + n.toLocaleString();
		},
		expand: function() {
			this.expanded = true;
		},
		collapse: function() {
			this.expanded = false;
		}, 
		scrollToDisclaimer: function() {
			window.scrollToAndPulse('.quotesDisclaimer:visible');
		}
	}
}
</script>